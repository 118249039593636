import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../containers/layout';
import BasePortableText from '@sanity/block-content-to-react'
import Figure from '../components/Figure';
import clientConfig from '../../client-config';
import ContentCard from '../components/ContentCardTest/ContentCard';
import SEO from '../components/seo';

export const query = graphql`
  query($slug: String) {
    sanityBlog(slug: { current: { eq: $slug } }) {
      blogIntroduction {
        title
        description
        publishedAt(formatString: "D MMMM, YYYY")
        thumbNail {
          asset {
            url
          }
        }
      }
      id
      slug {
        current
      }
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody(resolveReferences: {maxDepth: 5})
    }
  }
`;

const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure
  }
}

export default ({ data }) => {
  const { blogIntroduction  } = data.sanityBlog;

  return (
    <Layout>
      <SEO title={blogIntroduction.title} />
      <section className='about-us'>
        <div className="wrap">
          <div className="row">
            <ContentCard horizontal size="medium" image={blogIntroduction.thumbNail.asset.url}>
              <div>
                <h2>{blogIntroduction.title}</h2>
                <p>{blogIntroduction.description}</p>
                {blogIntroduction.showSocials ? (
                  <ul className="socials">
                    <li className="socials__item">
                      <a href="https://www.instagram.com/antjemachen" className="socials__link socials__link--insta"></a>
                    </li>
                    <li className="socials__item">
                      <a href="https://www.facebook.com/Antje-Machen-zwangerschapsadvies-112624847037326/" className="socials__link socials__link--facebook"></a>
                    </li>
                  </ul>
                ) : blogIntroduction.publishedAt ?
                    (
                      <p>{blogIntroduction.publishedAt}</p>
                    ) :
                    <Link to={blogIntroduction.buttonLink}>
                      <button className='button button--secondary'>{blogIntroduction.buttonText}</button>
                    </Link>
                }
              </div>
            </ContentCard>
          </div>
        </div>
        <div className="content-block">
          <div className="wrap">
            <div className="row center-lg">
              <div className="col-sm-12 col-lg-8">
                <BasePortableText
                  blocks={data.sanityBlog._rawBody} serializers={serializers} {...clientConfig.sanity}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}